import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { DialogSection } from "../../common/Dialog";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";

const DivStyle = styled.div`
  border: 1px solid ${Colors.border};
  background-color: ${Colors.white};

  p {
    padding-top: 10px;
  }
  .sub-section-status {
    padding-top: 10px;
  }
  .container-fluid {
    padding: 0 20px;
  }
  .section-title {
    color: ${Colors.tertiary};
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
`;

class ContactsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whois: null,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.reload();
  }

  reload = () => {
    this.setState({ whois: null, errorMessage: null });

    if (this.props.domain?.links.whois) {
      this.props.session.backendGet(
        this.props.domain.links.whois,
        (response) => {
          this.setState({ whois: response.whois });
        },
        (error) => {
          this.setState({ errorMessage: error });
        },
      );
    }
  };

  getWhois = () => {
    let content = "Loading, please wait";
    if (this.state.whoisError) {
      content = <p>Failed fetching data. Please try again later.</p>;
    } else if (this.state.whois !== null) {
      content = <pre>{this.state.whois}</pre>;
    }

    return <DialogSection>{content}</DialogSection>;
  };

  render() {
    return (
      <DivStyle>
        <Container key="parts" fluid={true}>
          {this.getWhois()}
        </Container>
      </DivStyle>
    );
  }
}

export default ContactsTab;
