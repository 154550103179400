import React, { Component } from "react";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";
import { FormatList } from "../../common/Utils";
import { TableBase } from "../../common/Tables";
import CreateOrderDialog from "../../order/CreateOrderDialog";
import { ActionButton } from "../../common/Buttons";

const DivStyle = styled.div`
  border: 1px solid ${Colors.border};
  background-color: ${Colors.white};
  table {
    margin-top: 15px;
  }
  img {
    max-height: 700px;
    max-width: 700px;
  }
  .container-fluid {
    padding: 0 20px;
  }
  .section-title {
    color: ${Colors.tertiary};
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
`;

class NameserversTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createOrderDialogDelegateShow: false,
    };
  }

  getActions = () => {
    let parts = [];
    let hasRoleEdit =
      this.props.session.hasRole("ORDER_CREATE") &&
      this.props.session.hasRole("DOMAIN_ADMIN_EDIT");
    parts.push(
      <ActionButton
        key="Nameservers"
        onClick={() => {
          this.setState({ createOrderDialogDelegateShow: true });
        }}
        text="Update Nameservers"
        icon={"edit"}
        prefix={"fas"}
        disabled={!hasRoleEdit}
        tooltip="Open the order dialog and fill in details to order a domain delegation, it allows you to change nameservers used by the domain."
        disabledTooltip="Your user account does not have privileges to order domain delegations, contact your client manager to activate this functionality"
      />,
    );
    return parts;
  };
  getNameservers = () => {
    const nameserverColumns = [
      {
        name: "name",
        label: "Name",
      },
      {
        label: "IPv4",
        contentFunction: (r) => FormatList(r.ipv4s),
      },
      {
        label: "IPv6",
        contentFunction: (r) => FormatList(r.ipv6s),
      },
    ];

    return (
      <TableBase
        columns={nameserverColumns}
        rows={this.props.domain.nameservers}
        actions={this.getActions()}
      />
    );
  };

  render() {
    let parameters = {};
    parameters.domainNames = this.props.domain.name;

    return (
      <div>
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.createOrderDialogDelegateShow}
          onClose={() =>
            this.setState({ createOrderDialogDelegateShow: false })
          }
          step="DOMAIN_NAMES"
          operation="DOMAIN-DELEGATE_DOMAIN"
          parameters={parameters}
        />
        {this.getNameservers()}
      </div>
    );
  }
}

export default NameserversTab;
