import React, { Component } from "react";
import { DetailsDialog } from "../common/Dialog";
import { Col, Row } from "react-bootstrap";
import { MainActionButton, NavigationButton } from "../common/Buttons";

export default class ActionDialog extends Component {
  getBody = () => {
    let body = [];

    body.push(
      <Row key="section-description">
        <Col md={12} className="section-description">
          This order is waiting on an action you need to perform as a customer.
          See below for further information what needs to be done. When the
          action is done the order will continue automatically.
        </Col>
      </Row>,
      <Row key="section-title-description">
        <Col md={12} className="section-title">
          Action Description
        </Col>
      </Row>,
      <Row key="section-field-description">
        <Col md={12} className="section-field">
          {this.props.data.description}
        </Col>
      </Row>,
      <Row key="section-title-message">
        <Col md={12} className="section-title">
          Order Message
        </Col>
      </Row>,
      <Row key="section-field-message">
        <Col
          md={12}
          className="section-field"
          style={{ whiteSpace: "pre-wrap" }}
        >
          {this.props.data.message}
        </Col>
      </Row>,
    );

    return body;
  };

  render() {
    if (this.props.data == null) {
      return null;
    }

    let title = "Customer Action";
    let body = this.getBody();

    let footer = [
      <NavigationButton
        onClick={this.props.onClose}
        type="button"
        text="Close"
      />,
    ];

    if (this.props.data.action === "pay") {
      footer.push(
        <MainActionButton
          key="pay"
          onClick={this.props.onPayAction}
          type="button"
          text="Pay"
        />,
      );
    }

    if (this.props.data.action === "authCode") {
      footer.push(
        <MainActionButton
          key="authCode"
          onClick={this.props.onAuthCodeAction}
          type="button"
          text="Update Auth Code"
        />,
      );
    }

    return (
      <DetailsDialog
        show={this.props.show}
        height="lg"
        width="lg"
        title={title}
        body={body}
        footer={footer}
      />
    );
  }
}
