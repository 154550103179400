import { darken, rgba } from "polished";
import React, { Component } from "react";
import { Navbar, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import Colors from "./theme/Colors";
import Icon from "./theme/Icons";
import Theme from "./theme/Theme";
import ContactArea from "./Team";

const Sidebar = styled.div`
  background-color: ${Colors.sidebarBackground};
  padding: 0 !important;
  width: ${Theme.sidebarWidth};
  position: fixed;
  top: ${Theme.topbarHeight};
  left: 0;
  bottom: 0;
  height: calc(100vh - ${Theme.topbarHeight});
  box-shadow: inset -10px 0 10px -5px ${rgba(Colors.black, 0.15)};

  .logo {
    display: block;
    height: auto;
    width: 100%;
    margin: 0 auto;
    padding: 20px 20px;
  }
  .logout {
    bottom: 0;
    position: absolute;
    display: block;
    width: 100%;
    text-align: left;
    background-color: ${darken(0.05, Colors.sidebarBackground)};
    height: 66px;
    padding: 15px 0;

    .logout-btn {
      float: left;
      margin: 5px 10px 0 20px;
      &:hover,
      &:focus {
        .fa {
          color: ${Colors.secondary};
          cursor: pointer;
        }
      }

      .fa {
        color: ${Colors.primary};
      }
    }
  }
`;

const Menu = styled.ul`
  list-style: none;
  padding: 0;
  height: calc(100% - ${Theme.contactAreaHeight});
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: ${darken(-0.35, Colors.sidebarBackground)};
  }

  li {
    a {
      text-decoration: none;

      &:hover {
        color: ${Colors.white};
      }
    }
  }
`;

const SideBarMenuItem = styled(NavItem)`
  && {
    padding-left: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    &.parent {
      padding-bottom: 0;
      padding-top: 15px;

      color: ${rgba(Colors.white, 0.35)};
      font-size: 12px;
      font-weight: 300;
      text-transform: uppercase;

      &:hover,
      &:focus {
        cursor: default;
        text-decoration: none;
      }
    }
    &.child {
      padding: 4px 20px;
      border-left: 4px solid transparent;
      position: relative;

      &.active {
        border-left: 4px solid ${Colors.primary};
        font-weight: 700;
        color: ${Colors.primary};

        &:hover {
          color: ${Colors.primary};
        }
      }

      color: ${Colors.white};
      font-weight: 300;
      font-size: 14px;

      svg {
        position: relative;
        top: 1px;
        margin-right: 5px;
      }

      &:hover,
      &focus {
        cursor: pointer;
        color: ${Colors.secondary};
      }
    }
  }
`;

const MobileNav = styled(Navbar)`
  && {
    background: ${Colors.sidebarBackground};
    margin-bottom: 0;
    position: fixed;
    height: 35px;
    width: 100%;
    z-index: 109;

    .styled-nav-item {
      border-color: ${Colors.primary};
      color: ${Colors.black};

      .icon-bar {
        background-color: ${Colors.primary};
      }

      a {
        padding-left: 0px;
        padding-right: 0px;
      }

      &:hover,
      &:focus {
        background-color: ${Colors.primary};
        border-color: ${Colors.sidebarBackground};

        .icon-bar {
          background-color: ${Colors.sidebarBackground};
        }
      }
    }

    .styled-nav-dropdown {
      border-color: ${Colors.primary};
      color: ${Colors.white};

      .icon-bar {
        background-color: ${Colors.primary};
        color: ${Colors.white};
      }

      a {
        padding-left: 0px;
        padding-right: 0px;
      }

      &:hover,
      &:focus {
        background-color: ${Colors.primary};
        border-color: ${Colors.sidebarBackground};

        .icon-bar {
          background-color: ${Colors.sidebarBackground};
        }
      }
    }

    .nav-link {
      padding-top: 0px;
      padding-bottom: 0px;
      color: ${Colors.white};
    }

    .nav-item {
      cursor: pointer;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      color: ${Colors.black};
    }

    .navbar-nav {
      li {
        a:hover,
        a:focus {
          color: ${Colors.primary};
        }

        &.active {
          a {
            background-color: "transparent";
            color: ${Colors.primary};
          }
        }
      }
      .open {
        a,
        a:focus {
          color: ${Colors.white};
          background-color: "transparent";
        }
        .dropdown-menu {
          background-color: ${darken(0.05, Colors.sidebarBackground)};

          li {
            a:hover {
              color: ${Colors.secondary};
            }
          }
          .active {
            a,
            a:hover {
              background-color: "transparent";
              color: ${Colors.primary};
            }
          }
        }
      }
    }
  }
`;

class PrimaryMenu extends Component {
  getItems() {
    let modules = this.props.session.getMenu();
    let items = [];

    for (let i = 0; i < modules.length; i++) {
      let module = modules[i];

      if (module.menuItems.length === 0) {
        continue;
      }

      items.push(this.getLevel1(module.label));

      for (let j = 0; j < module.menuItems.length; j++) {
        items.push(
          this.getLevel2(module.menuItems[j].label, module.menuItems[j].path),
        );
      }
    }

    return items;
  }

  getLevel1(label) {
    return (
      <SideBarMenuItem key={"level1-" + label} className="parent">
        {label}
      </SideBarMenuItem>
    );
  }

  getLevel2(label, path) {
    return (
      <LinkContainer
        to={path}
        className="child"
        key={"level2-" + label}
        onClick={this.props.onClick}
      >
        <SideBarMenuItem>
          <span
            style={{
              display: "inline-block",
              minWidth: "25px",
              textAlign: "center",
            }}
          >
            {this.getMenuIcon(label)}
          </span>
          {label}
        </SideBarMenuItem>
      </LinkContainer>
    );
  }

  getMenuIcon(iconName) {
    switch (iconName.toLowerCase()) {
      case "dashboard":
        return (
          <Icon
            prefix={"far"}
            name={"tachometer-alt"}
            className={"menu-icon"}
          />
        );
      case "portfolio":
        return (
          <Icon prefix={"far"} name={"briefcase"} className={"menu-icon"} />
        );
      case "projects":
        return <Icon prefix={"far"} name={"archive"} className={"menu-icon"} />;
      case "orders":
        return (
          <Icon
            prefix={"far"}
            name={"chevron-square-right"}
            className={"menu-icon"}
          />
        );
      case "invoices":
        return (
          <Icon
            prefix={"far"}
            name={"file-invoice-dollar"}
            className={"menu-icon"}
          />
        );

      case "watches":
        return (
          <Icon prefix={"far"} name={"binoculars"} className={"menu-icon"} />
        );
      case "cases":
        return (
          <Icon
            prefix={"far"}
            name={"exclamation-triangle"}
            className={"menu-icon"}
          />
        );
      case "domains":
        return <Icon prefix={"far"} name={"globe"} className={"menu-icon"} />;
      case "nameservers":
        return <Icon prefix={"far"} name={"server"} className={"menu-icon"} />;
      case "contacts":
        return (
          <Icon prefix={"far"} name={"address-book"} className={"menu-icon"} />
        );
      case "search tool":
        return (
          <Icon
            prefix={"far"}
            name={"search-location"}
            className={"menu-icon"}
          />
        );
      case "zones":
        return (
          <Icon prefix={"far"} name={"globe-africa"} className={"menu-icon"} />
        );
      case "publish":
        return (
          <Icon prefix={"far"} name={"paper-plane"} className={"menu-icon"} />
        );
      case "ssl certificates":
        return <Icon prefix={"fas"} name={"lock"} className={"menu-icon"} />;
      case "bulk":
        return (
          <Icon prefix={"far"} name={"industry"} className={"menu-icon"} />
        );
      case "users":
        return (
          <Icon prefix={"far"} name={"user-friends"} className={"menu-icon"} />
        );
      case "organisations":
        return (
          <Icon prefix={"far"} name={"building"} className={"menu-icon"} />
        );
      case "identity providers":
        return (
          <Icon prefix={"far"} name={"user-shield"} className={"menu-icon"} />
        );
      default:
        return null;
    }
  }

  getTeam() {
    const app = this.props.session.getApplication();
    if (!app.manager) {
      return null;
    }

    return (
      <ContactArea
        session={this.props.session}
        manager={app.manager}
        responsibles={app.responsibles}
        pageTipStatus={this.props.pageTipStatus}
        onPageTipClick={
          this.props.pageTipStatus === 2
            ? this.props.window.dismissPageTip
            : this.props.window.showPageTip
        }
      />
    );
  }

  render() {
    return (
      <Sidebar id="sidebar" key={"sidebar"} className={this.props.className}>
        <Menu bsStyle="pills" stacked="stacked" key={"MainMenu"}>
          {this.getItems()}
        </Menu>
        {this.getTeam()}
      </Sidebar>
    );
  }
}

export default PrimaryMenu;
