import { borderRadius, clearFix, darken, rgba, transitions } from "polished";
import "pretty-checkbox";
import React, { Component } from "react";
import {
  Badge,
  Col,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import styled from "styled-components";
import Colors from "./Colors";
import PortsLogoInvertedSvg from "../img/logo_abion_black.svg";
import SadSvg from "../img/sad.svg";
import { RotatingLines } from "react-loader-spinner";

const Theme = {
  sidebarWidth: "225px",
  topbarHeight: "50px",
  contactAreaHeight: "240px",
  mainWrapperPadding: "15px",
  borderColor: Colors.border,
  consultantImageSize: "88px",
};

const LogoWrapper = styled.div`
  width: ${(props) => props.width};
  padding: 5px 8px;
  text-align: left;

  img {
    margin: 0 auto;
    max-width: 100%;
  }
`;

const BorderColor = Theme.borderColor;

const Logo = (props) => {
  return (
    <LogoWrapper width={Theme.sidebarWidth}>
      <a href="/">
        <img
          src={PortsLogoInvertedSvg}
          height={props.height || "auto"}
          width={props.width || "auto"}
          alt="Ports.Management"
          className="logo"
        />
      </a>
    </LogoWrapper>
  );
};

const Panel = styled.div`
  background: ${Colors.white};
  ${borderRadius("top", "4px")};
  ${borderRadius("bottom", "4px")};
  box-shadow: 0 2px 6px -2px rgba(${Colors.black}, 0.15);
  padding: ${(props) => (props.padding ? props.padding : "8px 12px")};
  list-style: none;
  display: block;
  float: left;
  margin: 15px 15px 0 0;

  &.no-radius {
    ${borderRadius("top", "0px")};
    ${borderRadius("bottom", "0px")};
  }
  &.sidebar {
    min-height: calc(100vh - 66px);
    height: 100%;
    width: 100%;
    position: relative;
    margin: 0;
    z-index: 1000;
  }
  &.tab {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  &.block {
    width: 100%;
  }
`;

const FilterInput = styled(FormControl)`
  && {
    border-color: ${BorderColor};
    height: 34px;

    &:focus {
      box-shadow:
        inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(102 175 233 / 60%);
    }
  }
  &::placeholder {
    font-size: 12px;
    font-weight: 300;
    font-style: oblique;
  }
`;

const FilterInputAddon = styled.span`
  && {
    background: ${Colors.tertiary};
    color: ${Colors.white};
    border-color: ${Colors.tertiary};
    border-radius: 4px;
    font-weight: 600;
    line-height: 22px;
    height: 34px;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    padding: 6px 12px;
    text-align: center;
    vertical-align: middle;
    font-size: 12px;
  }
`;

const Input = styled(FormControl)`
  && {
    border: none;
    border-bottom: 1px solid ${Colors.primary};
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 10px;

    &:focus {
      outline: none transparent;
      box-shadow: none;
      border-bottom: 1px solid ${Colors.secondary};
    }
    &::placeholder {
      font-size: 12px;
      font-weight: 300;
      font-style: oblique;
    }

    &.form-control[disabled],
    fieldset[disabled],
    .form-control {
      background: ${Colors.disabled};
    }
  }
`;

const InputLabel = styled.span`
  text-transform: uppercase;
  font-size: 10px;
  color: ${Colors.primary};
  font-weight: 300;
`;

const Textarea = styled.textarea`
  border-color: #ccc;
  border-radius: 4px;
  width: 100%;

  margin-bottom: 10px;

  &:focus {
    box-shadow: none;
    border: 1px solid rgb(49, 167, 255);
  }

  &:focus-visible {
    outline: none;
  }

  &::placeholder {
    font-size: 12px;
    font-weight: 300;
    font-style: oblique;
  }
`;

class InputWithLabel extends Component {
  constructor(props) {
    super(props);
    this.state = { focused: false };
    this.changeLabelColor = this.changeLabelColor.bind(this);
    this.changeLabelColorBack = this.changeLabelColorBack.bind(this);
  }

  changeLabelColor() {
    this.setState({
      focused: true,
    });
  }

  changeLabelColorBack() {
    this.setState({
      focused: false,
    });
  }

  getLabel() {
    if (this.state.focused) {
      return (
        <InputLabel style={{ color: Colors.secondary }}>
          {this.props.label}
        </InputLabel>
      );
    } else {
      return <InputLabel>{this.props.label}</InputLabel>;
    }
  }

  render() {
    if (this.props.type && this.props.type === "textarea") {
      return (
        <div>
          {this.getLabel()}
          <Textarea
            className="textarea"
            onFocus={this.changeLabelColor}
            onBlur={this.changeLabelColorBack}
            placeholder={this.props.placeholder}
            value={this.props.value}
            onChange={this.props.onChange}
            rows={this.props.rows ? this.props.rows : "10"}
            autoFocus={this.props.autoFocus}
          />
        </div>
      );
    }
    return (
      <div>
        {this.getLabel()}
        <Input
          onFocus={this.changeLabelColor}
          onBlur={this.changeLabelColorBack}
          {...this.props}
        />
      </div>
    );
  }
}

const StyledCheckBox = styled.label`
  margin-bottom: 20px;
  font-weight: 400;
`;

class CheckBox extends Component {
  render() {
    return (
      <StyledCheckBox>
        <input
          type="checkbox"
          defaultChecked={
            this.props.defaultChecked ? this.props.defaultChecked : true
          }
          onChange={this.props.onChange}
        />
        &nbsp;&nbsp;{this.props.label}
      </StyledCheckBox>
    );
  }
}

const PaddedContent = styled.div`
  &.hidden {
    display: none;
  }
  display: block;
  position: relative;
  padding: ${(props) => props.padding || 0};
  height: auto;
  min-height: ${(props) => (props.height ? props.height : "auto")};
  width: 100%;
`;

const ClearFix = styled.div`
  ${clearFix()};
`;

const Product = styled(Panel)`
  margin-top: ${(props) => (props.padded ? "20px" : "0")};
  border: ${(props) => (props.list ? null : `1px solid ${Theme.borderColor}`)};
  border-bottom: ${(props) =>
    props.list ? `1px solid ${Theme.borderColor}` : null};
  border-left: ${(props) =>
    props.list ? `1px solid ${Theme.borderColor}` : null};
  border-right: ${(props) =>
    props.list ? `1px solid ${Theme.borderColor}` : null};
  border-top: ${(props) => (props.list ? `none` : null)};

  margin-right: ${(props) => (props.list ? `0` : null)};

  box-shadow: ${(props) =>
    props.list ? "none" : "0 2px 6px -2px rgba(Colors.black,0.15)"};

  ${(props) => borderRadius("top", props.list ? "0px" : "4px")};
  ${(props) => borderRadius("bottom", props.list ? "0px" : "4px")};

  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  max-height: ${(props) => (props.list ? `80px` : null)};
  padding: ${(props) => (props.padding ? props.padding : null)};
  ${transitions("max-height 0.25s ease-in-out")}
  &.expanded {
    border-top: none;
    max-height: 500px;
    box-shadow: 0 0 20px 0 rgba(${Colors.black}, 0.35);
    z-index: 199;
  }

  &.project {
    a {
      color: ${Colors.black};
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: ${Colors.secondary};
      }
    }
  }
`;

const ProductDivider = styled.div`
  position: relative;
  display: block;
  height: 1px;
  border-bottom: 1px solid ${BorderColor};
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

const Truncated = styled.div`
  position: relative;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Mascot = styled.img`
  display: block;
  margin: 50px auto 0;
  width: 60px;
  height: auto;
`;

const Sorry = styled.h3`
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${darken(0.25, BorderColor)};
  margin-bottom: 50px;
`;

const NoDataComponent = (props) => {
  const ImgSrc = SadSvg;
  return (
    <Col xs={6} xsOffset={3} className={"center transparent"}>
      <Mascot src={ImgSrc} alt="No items found" />
      <Sorry>No items to show</Sorry>
    </Col>
  );
};

const CardTitle = styled.div`
  && {
    margin-bottom: 0;
    font-size: ${(props) => (props.isTitle ? "20px" : "14px")};
    font-weight: 600;

    a {
      &:hover {
        text-decoration: underline;
        color: ${Colors.tertiary};
      }
    }
  }
`;

const Header = styled.div`
  background: ${Colors.white};
  border: 1px solid ${BorderColor};
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 5px;

  .header-link {
    text-decoration: underline;
    color: ${Colors.tertiary};
    font-size: 12px;
    font-weight: 600;
    text-transform: lowercase;
    padding: 5px 0 0 0;
  }
`;

const TitleWrapper = styled.div`
  display: block;
  margin-right: 25px;
`;

const HeaderMeta = styled(Col)`
  text-align: center;
  margin-bottom: 25px;
`;

const HeaderMetaLastRow = styled(Col)`
  text-align: center;
  margin-bottom: 0;
`;

const HeaderMetaLabel = styled.span`
  display: block;
  font-size: 0.85em;
  margin-bottom: 3px;
  color: ${rgba(Colors.black, 0.5)};
`;

const HeaderMetaIcon = styled.div`
  display: block;
  min-height: 20px;
  padding-top: 5px;

  &.serviceStatusEnabled {
    color: ${Colors.success};
  }
`;

const HeaderMetaText = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 0 0 0;
  border-radius: 4px;

  &.status {
    font-weight: bold;
  }

  &.zoneStatusOk {
    color: ${Colors.success};
    font-weight: bold;
  }

  &.zoneStatusError {
    border: 1px solid ${Colors.errorBorder};
    background-color: ${Colors.errorBackground};
    color: ${Colors.error};
    font-weight: bold;
    padding: 10px 0 10px 0;
  }

  &.zoneStatusError svg {
    margin-left: 10px;
  }

  &.zoneStatusWarning {
    border: 1px solid ${Colors.warningBorder};
    background-color: ${Colors.warningBackground};
    color: ${Colors.warning};
    font-weight: bold;
    padding: 10px 0 10px 0;
  }

  &.zoneStatusWarning svg {
    margin-left: 10px;
  }

  &.publishStatusOk {
    color: ${Colors.success};
    font-weight: bold;
  }

  &.publishStatusDirty {
    border: 1px solid ${Colors.warningBorder};
    background-color: ${Colors.warningBackground};
    color: ${Colors.warning};
    font-weight: bold;
    padding: 10px 0 10px 0;
  }

  &.publishStatusDirty svg {
    margin-left: 10px;
  }

  &.publishStatusDeleted {
    font-weight: bold;
  }

  &.dnsSecStatusOk {
    color: ${Colors.success};
    font-weight: bold;
  }

  &.dnsSecStatusPending {
    color: ${Colors.warning};
    font-weight: bold;
  }

  &.dnsSecStatusDisabled {
    font-weight: bold;
  }

  &.dnsType {
    font-weight: bold;
    > svg {
      margin: 0;
      margin-left: 5px;
    }
  }
  &.lower {
    text-transform: lowercase;
  }
`;

const HeaderMetaSeparator = styled.span`
  display: inline-block;
  width: 40px;
`;

const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${Colors.tertiary};
  margin: 0;

  .type {
    font-weight: 100;
    margin-left: 5px;
    color: ${rgba(Colors.tertiary, 0.75)};
  }
  span {
    font-weight: 300;
  }
`;

const Organisation = styled.h3`
  font-size: 16px;
  color: ${rgba(Colors.black, 0.5)};
  font-weight: 300;
  margin-top: 5px;
  margin-bottom: 0;
`;

const Tip = styled(Tooltip)`
  .tooltip-inner {
    max-width: none;
    white-space: nowrap;
  }
`;

const StyledLoaderDiv = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.25;
`;

class Loader extends Component {
  render() {
    if (!this.props.loaded) {
      return (
        <StyledLoaderDiv>
          <RotatingLines
            strokeColor="grey"
            strokeWidth="3"
            animationDuration="0.75"
            width="80"
            visible="true"
          />
        </StyledLoaderDiv>
      );
    } else {
      return this.props.children;
    }
  }
}

class TruncatedTitle extends Component {
  checkTruncated(string, isTitle, breakAfter = 50) {
    if (string.length > breakAfter) {
      const tooltip = <Tip id="tooltip">{string}</Tip>;
      return (
        <OverlayTrigger placement="top" overlay={tooltip}>
          <CardTitle isTitle={isTitle}>
            <Truncated>{string}</Truncated>
          </CardTitle>
        </OverlayTrigger>
      );
    } else {
      return <CardTitle isTitle={isTitle}>{string}</CardTitle>;
    }
  }

  render() {
    return this.checkTruncated(
      this.props.data,
      this.props.isTitle,
      this.props.breakAfter,
    );
  }
}

const SwitchCheckbox = (label, defaultChecked, onChange) => {
  return (
    <div key="switch-checkbox" className="pretty p-switch p-fill">
      <input
        type="checkbox"
        defaultChecked={defaultChecked}
        onClick={onChange}
      />
      <div className="state p-success">
        <label>{label}</label>
      </div>
    </div>
  );
};

const IsEmpty = (obj) => {
  if (obj == null) return true;
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;
  if (typeof obj !== "object") return true;
  for (let key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
};

const CardInfo = styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  display: ${(props) => (props.hidden ? "none" : "block")};
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  margin-bottom: ${(props) => (props.pushDown ? "7px" : 0)};
`;

const CardInfoLabel = styled.div`
  display: block;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  font-size: 9px;
  margin: 3px 0;
  color: ${Colors.transparent};
`;

const CardInfoValue = styled.div`
  text-align: ${(props) => (props.align ? props.align : "inherit")};
  font-size: 12px;
`;

const Tag = styled(Badge)`
  && {
    background: ${Colors.primary} !important;
    background-color: ${Colors.primary} !important;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 1.5px;
    color: ${Colors.white} !important;
    text-transform: uppercase;

    &.multi {
      margin-bottom: 3px;

      &:last-child {
        margin-right: 0;
      }

      &:after {
        clear: both;
      }
    }

    &.pending,
    &.dashboard {
      background: ${Colors.warning} !important;
      background-color: ${Colors.warning} !important;
      color: ${Colors.tertiary} !important;
    }
    &.dnsadminpublishzones,
    &.dnsadminorderdns,
    &.dnsadminviewzones,
    &.dnsadmineditzones,
    &.parameter,
    &.active {
      background: ${Colors.tertiary} !important;
      background-color: ${Colors.tertiary} !important;
      color: ${Colors.white} !important;
    }

    &.finished {
      background: ${Colors.primary} !important;
      background-color: ${Colors.primary} !important;
      color: ${Colors.white} !important;
    }

    &.clear {
      ${clearFix()}
    }
  }
`;

const ObliqueText = styled.div`
  font-style: italic;
  color: ${Colors.tertiary};
`;

export {
  Panel,
  BorderColor,
  Input,
  InputWithLabel,
  FilterInput,
  FilterInputAddon,
  InputLabel,
  ClearFix,
  CheckBox,
  PaddedContent,
  Product,
  ProductDivider,
  Truncated,
  NoDataComponent,
  CardInfo,
  CardInfoLabel,
  CardInfoValue,
  Tag,
  TruncatedTitle,
  ObliqueText,
  SwitchCheckbox,
  Loader,
  Logo,
  PageTitle,
  Organisation,
  TitleWrapper,
  Header,
  HeaderMeta,
  HeaderMetaLastRow,
  HeaderMetaLabel,
  HeaderMetaText,
  HeaderMetaSeparator,
  HeaderMetaIcon,
  IsEmpty,
};
export default Theme;
