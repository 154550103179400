import React, { Component } from "react";
import { TableBackend, TableFilter } from "../../common/Tables";
import {
  FormatDateMonospace,
  FormatList,
  FormatOrganisation,
} from "../../common/Utils";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";
import {
  ActionButton,
  BlockingActionButton,
  DropdownButton,
  MainActionButton,
} from "../../common/Buttons";
import DomainDetailsDialog from "./DomainDetailsDialog";
import fileDownload from "js-file-download";
import OrganisationFilter from "../../common/OrganisationFilter";
import CreateOrderDialog from "../../order/CreateOrderDialog";
import { FormatFeatures } from "../../common/DomainUtils";
import { InternalLink } from "../../common/Link";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
    margin-bottom: 2px;
  }
`;

export default class DomainList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        accountIds: "",
        freetext: "",
        onExpire: "ALL",
        reloadFlag: false,
      },
      createOrderDialogRegisterShow: false,
      createOrderDialogRegistrarTransferShow: false,
      domain: null,
      submit: false,
      errorMessage: null,
      exported: 0,
    };
    this.analyzeDomains = this.analyzeDomains.bind(this);
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Domains",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().domainadmin.links.domains;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&onExpireFilter=" + parameters.onExpire;
    url += "&accountIdFilter=" + parameters.accountIds;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  getNameservers = (nameservers) => {
    let names = [];
    nameservers.forEach((o) => names.push(o.name));
    return names;
  };

  getOnExpireLabel = (onExpire) => {
    if (onExpire === "RENEW") {
      return "Domains that will be Renewed";
    } else if (onExpire === "CANCEL") {
      return "Domains that will be Cancelled";
    } else {
      return "All On Expiry";
    }
  };

  exportDomains = () => {
    let url = this.props.session.getModules().domainadmin.links.exportDomains;
    url += "?onExpireFilter=" + this.state.parameters.onExpire;
    url += "&accountIdFilter=" + this.state.parameters.accountIds;
    url +=
      "&freetextFilter=" + encodeURIComponent(this.state.parameters.freetext);

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "domains.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  analyzeDomains = () => {
    this.props.history.push({
      pathname: "/domainadmin/searchtool",
      state: { analyze: true },
    });
  };

  render() {
    let actions = [];

    let disabled =
      !this.props.session.hasRole("ORDER_CREATE") ||
      !this.props.session.hasRole("DOMAIN_ADMIN_EDIT");
    let invisible = this.props.session.hasRole("SYS_ADMIN");

    actions.push(
      <MainActionButton
        key="register-domain"
        onClick={() => this.setState({ createOrderDialogRegisterShow: true })}
        text="Register Domain"
        icon="plus"
        prefix="fas"
        tooltip="Open the order dialog and fill in details to order a new domain registration."
        disabled={disabled}
        disabledTooltip="Your user account does not have privileges to order domain registrations, contact your client manager to activate this functionality."
        invisible={invisible}
      />,
    );

    actions.push(
      <MainActionButton
        key="transfer-in"
        onClick={() =>
          this.setState({ createOrderDialogRegistrarTransferShow: true })
        }
        text="Transfer In"
        icon="plus"
        prefix="fas"
        tooltip="Open the order dialog and fill in details to order a domain registrar transfer to transfer an existing domain to Abion."
        disabled={disabled}
        disabledTooltip="Your user account does not have privileges to order domain registrations, contact your client manager to activate this functionality."
        invisible={invisible}
      />,
    );

    actions.push(
      <BlockingActionButton
        key="ExportDomains"
        onClick={this.exportDomains}
        text={"Export"}
        icon={"cloud-download"}
        prefix={"fas"}
        tooltip="Exports the domains to an Excel file. Note that only rows matching current filter are exported."
        reload={this.state.exported}
      />,
    );
    actions.push(
      <ActionButton
        key="Analyze"
        onClick={this.analyzeDomains}
        text="Analyze"
        prefix="fas"
        icon="search-location"
        tooltip="Open search tool and analyze brands and TLDs present in your domain portfolio."
      />,
    );

    const filters = [
      <OrganisationFilter
        key="organisationFilter"
        session={this.props.session}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              accountIds: o,
            }),
          })
        }
      />,
      <DropdownButton
        id="onExpire"
        key="onExpire"
        title={this.getOnExpireLabel(this.state.parameters.onExpire)}
        style={{ verticalAlign: "top" }}
      >
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "ALL",
              }),
            })
          }
        >
          {this.getOnExpireLabel("ALL")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "RENEW",
              }),
            })
          }
        >
          {this.getOnExpireLabel("RENEW")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "CANCEL",
              }),
            })
          }
        >
          {this.getOnExpireLabel("CANCEL")}
        </Dropdown.Item>
      </DropdownButton>,
      <TableFilter
        key="freeText"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    const columns = [
      {
        label: "Domain",
        name: "name",
        sortable: true,
        contentFunction: (r) => (
          <InternalLink
            to={"/domainadmin/domains/" + r.id}
            text={r.name}
            className="table-link"
          />
        ),
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: true,
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
      {
        label: "Registrant",
        name: "owner",
      },
      {
        label: "Nameservers",
        name: "nameservers",
        contentFunction: (r) => FormatList(this.getNameservers(r.nameservers)),
      },
      {
        label: "Features",
        contentFunction: (r) => FormatFeatures(r),
      },
      {
        label: "Expiry Date",
        name: "expires",
        sortable: true,
        style: { width: "120px" },
        contentFunction: (r) => FormatDateMonospace(r.expires),
      },
      {
        label: "On Expiry",
        name: "onExpire",
        style: { width: "120px" },
      },
      {
        label: "Comments",
        name: "comments",
        contentFunction: (r) => (
          <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
            <table>
              <tbody>
                <tr>
                  <td width={"250px"}>{r.comments}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ),
      },
    ];

    return (
      <PageStyle>
        <TableBackend
          session={this.props.session}
          window={this.props.window}
          filters={filters}
          columns={columns}
          sorting="name"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
          actions={actions}
        />
        <DomainDetailsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.showDetailsDialog}
          domain={this.state.domain}
          onClose={this.hideDetailsDialog}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.createOrderDialogRegisterShow}
          onClose={() =>
            this.setState({ createOrderDialogRegisterShow: false })
          }
          step="OPERATION"
          operation="DOMAIN-REGISTER_DOMAIN"
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.createOrderDialogRegistrarTransferShow}
          onClose={() =>
            this.setState({ createOrderDialogRegistrarTransferShow: false })
          }
          step="OPERATION"
          operation="DOMAIN-REGISTRAR_TRANSFER"
        />
      </PageStyle>
    );
  }
}
